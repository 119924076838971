import React from 'react'
import { Field, ErrorMessage } from 'formik'
import FormikControlErrorMessage from '../FormikControlErrorMessage'
import newId from '../../../utils/newId';

function FormikControlString(props) {
    const { label, name, ...rest } = props
    const htmlId = newId()
    return (
        <div className="mt-4 mb-2">
            <label htmlFor={htmlId} className="form-control block text-lg py-1">
                <span className="text-primary-300 ml-1">{label}</span>
            </label>
            <Field
                type="text"
                label={label}
                title={label}
                id={htmlId}
                name={name}
                aria-describedby={name + 'Error'}
                {...rest}
                className="form-input mt-1 block w-full text-primary-700 text-center tracking-widest"
            />
            <ErrorMessage component={FormikControlErrorMessage} name={name} label={label} id={name + 'Error'} />
        </div>
    )
}

export default FormikControlString
