import React from 'react'
import FormikControlString from './FormikControlTypes/FormikControlString'
import FormikControlText from './FormikControlTypes/FormikControlText';
import FormikControlPassword from './FormikControlTypes/FormikControlPassword'
import FormikControlCheckbox from './FormikControlTypes/FormikControlCheckbox'
import FormikControlCheckboxGroup from './FormikControlTypes/FormikControlCheckboxGroup';
import FormikControlRadioGroup from './FormikControlTypes/FormikControlRadioGroup';
import FormikControlUserConsent from './FormikControlTypes/FormikControlUserConsent'


export function FormikControl(props) {
    const { control, ...rest } = props
    switch (control) {
        case 'string':
            return <FormikControlString {...rest} />
        case 'password':
            return <FormikControlPassword {...rest} />
        case 'text':
            return <FormikControlText {...rest} />
        // case 'select':
        //     return <FormikControlSelect {...rest} />
        case 'radio-group':
            return <FormikControlRadioGroup {...rest} />
        case 'checkbox':
            return <FormikControlCheckbox {...rest} />
        case 'checkbox-group':
            return <FormikControlCheckboxGroup {...rest} />
        case 'userconsent':
            return <FormikControlUserConsent {...rest} />
        default:
            return null
    }
}

export default FormikControl
