import React from 'react'
import { Field, ErrorMessage } from 'formik'
import FormikControlErrorMessage from '../FormikControlErrorMessage'
import newId from '../../../utils/newId';

function FormikControlCheckboxGroup(props) {
    const { name, label, options, ...rest } = props
    return (
        <div className="form-control mt-6 mb-4">
            <label className="form-control block text-lg text-gray-800">{label}</label>
            <Field name={name}>
                {({ field }) => {
                    return options.map(option => {
                        const htmlId = newId()
                        return (
                            <React.Fragment key={option.label}>
                                <label htmlFor={htmlId} className="text-gray-600 flex items-center cursor-pointer hover:bg-gray-100">
                                    <input
                                        type='checkbox'
                                        id={htmlId}
                                        {...field}
                                        {...rest}
                                        value={option.value}
                                        checked={field.value.includes(option.value)}
                                        className='form-checkbox flex-shrink-0 m-2 text-primary-700'
                                    />
                                    <span>{option.label}</span>
                                </label>
                            </React.Fragment>
                        )
                    })
                }}
            </Field>
            <ErrorMessage component={FormikControlErrorMessage} name={name} />
        </div>
    )
}

export default FormikControlCheckboxGroup
