import React from 'react'

function PrimaryButton(props) {
    const {type, text, action, disabled} = props
    return <>
        <button
            type={type||'button'}
            className="btn-primary"
            onClick={action}
            disabled={disabled}
            aria-disabled={disabled}
        >
            <div className="sm:text-lg flex-grow text-center px-2">{text||'Primary Button'}</div>
        </button>
        {disabled && <div tabIndex="0" className="sr-only">Disabled button: {text||'Primary Button'}. Please fill all necessary fields.</div>}
    </>
}
export default PrimaryButton
