import { FormFeedback } from './components/forms/FormFeedback'

function App() {
    return (
        <div className="app">
            <FormFeedback />
        </div>
    )
}

export default App
