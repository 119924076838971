import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ButtonPrimary from '../controls/ButtonPrimary'

export const FormUserconsent = (props) => {
    const { setPopupTitle, close } = props

    useEffect(() => {
        if (setPopupTitle) { setPopupTitle('Политика обработки ПД') }
    })

    return (

        <div className="overflow-y-auto max-h-screen-80">
            <div className="p-3 text-primary-800">

                <div className="text-xl font-bold my-4">Cогласие на обработку персональных данных физических лиц, обратившихся через форму обратной связи</div>

                <p className="text-sm">Пользователь, отправляя сообщение через форму обратной связи на интернет-сайте chiefred.com, принимает
                настоящее Согласие на обработку персональных данных (далее – Согласие). Принятием Согласия является
                сообщение, отправленное через форму обратной связи на интернет-сайте. Действуя свободно, своей волей и в
                своем интересе, а также подтверждая свою дееспособность, Пользователь дает свое согласие ИП Петушков Сергей
            Борисович, ОГРНИП 314352519600050, на обработку своих персональных данных со следующими условиями:</p>
                <p className="text-sm">
                    1. Данное Согласие дается на обработку персональных данных, как без использования средств автоматизации, так
                    и с их использованием.</p>
                <p className="text-sm">2. Согласие дается на обработку следующих моих персональных данных -
                Персональные данные, не являющиеся специальными или биометрическими: фамилия, имя, отчество; номера
            контактных телефонов; адреса электронной почты.</p>
                <p className="text-sm">3. Персональные данные не являются общедоступными.</p>
                <p className="text-sm">4. Цель обработки персональных данных: Предоставление ответов на поступающие вопросы.</p>
                <p className="text-sm"> 5. Основанием для обработки персональных данных является: Ст. 24 Конституции Российской Федерации; ст.6
            Федерального закона №152-ФЗ «О персональных данных».</p>
                <p className="text-sm">6. В ходе обработки с персональными данными будут совершены следующие действия: сбор; запись;
                систематизация; накопление; хранение; уточнение (обновление, изменение); извлечение; использование; передача
            (распространение, предоставление, доступ); блокирование; удаление; уничтожение.</p>
                <p className="text-sm">7. Обработка
                персональных данных может быть прекращена по запросу субъекта персональных данных. Хранение персональных
                данных, зафиксированных на бумажных носителях осуществляется согласно Федеральному закону №125-ФЗ «Об
                архивном деле в Российской Федерации» и иным нормативно правовым актам в области архивного дела и архивного
            хранения.</p>
                <p className="text-sm">8. Согласие дается в том числе на возможную трансграничную передачу персональных данных в страны, не
            обеспечивающие адекватную защиту прав субъектов персональных данных.</p>
                <p className="text-sm">9. Согласие может быть отозвано субъектом персональных данных или его представителем путем направления
            письменного заявления в ИП Петушков Сергей Борисович.</p>
                <p className="text-sm">10. В случае отзыва субъектом персональных данных или его представителем согласия на обработку персональных
                данных ИП Петушков Сергей Борисович вправе продолжить обработку персональных данных без согласия субъекта
                персональных
                данных при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2
            статьи 11 Федерального закона №152-ФЗ «О персональных данных» от 27.07.2006 г.</p>
                <p className="text-sm">11. Настоящее согласие действует все время до момента прекращения обработки персональных данных, указанных
            в п.7 и п.9 данного Согласия.</p>

            </div>
            <div className="px-2 py-4">
                <ButtonPrimary type="submit" text="Закрыть" action={close} />
            </div>
        </div>
    )
}

FormUserconsent.propTypes = {
    setPopupTitle: PropTypes.func,
    close: PropTypes.func,
}
