import React from 'react'
import { Field, ErrorMessage } from 'formik'
import FormikControlErrorMessage from '../FormikControlErrorMessage'
import newId from '../../../utils/newId';

function FormikControlCheckbox(props) {
    const { name, label, ...rest } = props
    return (
        <div className="form-control mt-6 mb-4">
            <Field name={name}>
                {({ field }) => {
                    const htmlId = newId()
                    return <>
                        <label htmlFor={htmlId} className="flex items-center p-1 rounded-md cursor-pointer hover:bg-gray-100">
                            <input type="checkbox" id={htmlId} name={name} {...rest} className="form-checkbox text-primary-800" {...field} checked={field.value} />
                            <span className="ml-4 text-base sm:text-lg ">{label}</span>
                        </label>
                    </>
                }}
            </Field>
            <ErrorMessage component={FormikControlErrorMessage} name={name} />
        </div>
    )
}

export default FormikControlCheckbox
