import React from 'react'

function FormikControlErrorMessage(props) {
    const {name, label, className, children, ...rest} = props
    const classNames = ['error text-right text-sm text-red-400 mt-1 mr-1', className].join(' ');
    return <div className={classNames} {...rest}>
        <span className="sr-only">{label} field validation error message: </span>
        <span>{children}</span>
    </div>
}

export default FormikControlErrorMessage
