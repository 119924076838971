import React from 'react'
import PropTypes from 'prop-types'
import { motion, AnimatePresence } from "framer-motion"
import { RemoveScroll } from 'react-remove-scroll'
import useKeypress from '../hooks/useKeypress'

export const Popup = (props) => {
    const { opened, title, close, children } = props

    const popupMotionVariants = {
        hidden: {
            opacity: 0,
            scaleY: 0,
            transition: {
                type: "linear",
                duration: .3
            }
        },
        visible: {
            opacity: 1,
            scaleY: 1,
            transition: {
                type: "spring",
                damping: 50,
                stiffness: 50000,
            }
        },
    }

    useKeypress('Escape', () => {
        opened && close && close()
    })

    return <>
        <AnimatePresence>
            {opened &&
                <div className="modal z-50 fixed w-full min-h-screen top-0 left-0 flex items-center justify-center select-none font-gilroy">
                    <div className="modal-overlay absolute top-0 left-0 w-full h-full bg-primary-900 opacity-50 backdrop-blur"></div>
                    <div className="modal-wrapper relative w-full">
                        <div className="max-w-full max-h-screen sm:max-w-lg mx-auto">
                            <motion.div
                                variants={popupMotionVariants}
                                initial="hidden"
                                animate="visible"
                                exit="hidden"
                            >
                                <div tabIndex={0} aria-label={title} aria-modal className="bg-gray-100 shadow-lg border-white border-t-2 border-b-2 sm:border-2 sm:mx-4 sm:rounded-lg focus:outline-none" onClick={e => e.stopPropagation()}>
                                    <div className="px-3 h-12 bg-primary-800 text-white border-b sm:rounded-t-lg border-white font-semibold relative flex items-center">
                                        <div className="modal-title w-full text-xl truncate pr-6">{title}</div>
                                        <button type="button" aria-label="Close modal" className="absolute top-0 right-1 w-10 h-10 text-4xl leading-none cursor-pointer flex items-center justify-center transform origin-center hover:scale-125 active:scale-110 focus:outline-none" onClick={close} title="Закрыть (Esc)">&times;</button>
                                    </div>
                                    <RemoveScroll removeScrollBar={false}>
                                        {children}
                                    </RemoveScroll>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            }
        </AnimatePresence>

    </>
}

export default Popup


Popup.propTypes = {
    opened: PropTypes.bool,
    title: PropTypes.string,
    close: PropTypes.func,
    children: PropTypes.element,
}
