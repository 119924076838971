import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import ReCAPTCHA from "react-google-recaptcha"
import FormikControl from '../FormikControls'
import ButtonPrimary from '../controls/ButtonPrimary'
import { Persist } from 'formik-persist'
import ProcessingIndicator from '../ProcessingIndicator'
import axios from 'axios'
import { FormUserconsent } from './FormUserconsent'
import { Popup } from '../Popup';
export const FormFeedback = () => {

    const [popupTitle, setPopupTitle] = useState('')
    const [popupContent, setPopupContent] = useState(<></>)
    const [popupOpened, setPopupOpened] = useState(false)

    const close = () => {
        setPopupOpened(false)
    }

    const openUserconsentPopup = () => {
        setPopupContent(<FormUserconsent setPopupTitle={setPopupTitle} setPopupContent={setPopupContent} setPopupOpened={setPopupOpened} close={close} />)
        setPopupOpened(true)
    }


    const [formState, setFormState] = useState('input') // input / processing / success / failure
    const [formMessage, setFormMessage] = useState('Неизвестная ошибка')

    const initialValues = {
        name: '',
        email: '',
        message: '',
        userconsent: false,
    }

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Нужно заполнить')
            .max(100, val => `Превышена максимальная длина в ${val.max} символов`),
        email: Yup.string()
            .required('Нужно заполнить')
            .email('Не похоже на валидный email-адрес')
            .max(100, val => `Превышена максимальная длина в ${val.max} символов`),
        message: Yup.string()
            .required('Нужно заполнить')
            .max(25000, val => `Превышена максимальная длина в ${val.max} символов`),
        userconsent: Yup.boolean()
            .oneOf([true], 'Согласие необходимо'),
    })

    const recaptchaRef = React.useRef()

    const onSubmitWithReCAPTCHA = async (values, { resetForm }) => {
        setFormState('processing')
        // @ts-ignore
        if (recaptchaRef.current && recaptchaRef.current.executeAsync) {

            let token
            try {
                await Promise.race([
                    new Promise((_, reject) => setTimeout(() => {
                        reject(new Error('timeout'))
                    }, 5000)),
                    // @ts-ignore
                    recaptchaRef.current.executeAsync(),
                ])
                    .then((v) => {
                        token = v
                    })
            } catch (error) {
                console.error('app error - catch in try around ReCaptcha Promise.race', error)
                token = false
            }

            if (token) {
                values.recaptcha = token

                let data
                try {
                    await Promise.race([
                        new Promise((_, reject) => setTimeout(() => {
                            reject(new Error('timeout'))
                        }, 30000)),
                        axios({
                            method: 'post',
                            url: '/msg/',
                            data: values
                        })
                    ])
                    .then((v) => {
                        data = v.data
                    })
                } catch (error) {
                    console.error('app error - catch in try around MSG Promise.race', error)
                    data = {state: 'timeout', errors: 'Сервер не ответил'}
                }

                //console.log(`data`, data)
                if (data && 'success' === data.state) {
                    resetForm()
                    resetForm() //api bug workaround - need to call reset twice
                    setFormState('success')
                    return
                } else if (data && 'error' === data.state && data.errors) {
                    setFormMessage(Array(data.errors).reduce((et, e) => et += `${e}. `))
                } else if (data && 'timeout' === data.state) {
                    setFormMessage('Ответ сервера не получен. Проверьте соединение с Интернетом.')
                } else {
                    setFormMessage('Ответ сервера не распознан')
                }

            } else {
                console.error('app error - reCaptcha token is empty')
                setFormMessage('Токен reCaptcha пуст (проверьте Интернет-соединение)')
            }
        } else {
            console.error('app error - reCaptcha is not rendered')
            setFormMessage('reCaptcha не найдена (проверьте Интернет-соединение)')
        }
        setFormState('failure')
    }

    switch (formState) {
        case 'success':
            return (
                <div className="flex flex-col justify-center items-center py-16">
                    <div className="text-xl text-center text-secondary-400 font-bold py-2">Ваше&nbsp;сообщение успешно&nbsp;отправлено</div>
                    <div className="text-lg text-center py-2">Я&nbsp;стараюсь&nbsp;отвечать в&nbsp;течение&nbsp;одного рабочего&nbsp;дня</div>
                </div>
            )
        case 'failure':
            return (
                <div className="flex flex-col justify-center items-center p-2">
                    <div className="text-xl text-center font-bold pt-16 text-red-400">При обработке запроса возникла ошибка!</div>
                    <div className="text-base text-center py-4">{formMessage}</div>
                    <div className="text-lg text-center pb-16">Повторите действие позже, либо воспользуйтесь другим способом связи</div>
                    <ButtonPrimary type="button" text="Назад к форме" action={() => { setFormState('input') }} />
                </div>
            )
        default: // input / processing
            return (
                <>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmitWithReCAPTCHA}
                    >
                        {formik => {
                            return (
                                <div className="relative">
                                    <div className="">
                                        <Form className="feedback-form" autoComplete="off">
                                            <div className="px-2">
                                                <div className="flex flex-col md:flex-row">
                                                    <div className="flex-grow mr-0 md:mr-4">
                                                        <FormikControl
                                                            control='string'
                                                            name='name'
                                                            label='Ваше имя *'
                                                            placeholder=''
                                                        />
                                                    </div>
                                                    <div className="flex-grow ml-0 md:ml-4">
                                                        <FormikControl
                                                            control='string'
                                                            name='email'
                                                            label='Email *'
                                                            placeholder=''
                                                        />
                                                    </div>
                                                </div>
                                                <FormikControl
                                                    control='text'
                                                    name='message'
                                                    label='Сообщение *'
                                                    placeholder=''
                                                />
                                                <FormikControl
                                                    control='userconsent'
                                                    name='userconsent'
                                                    label_template='Согласен(на) с #политикой обработки ПД#'
                                                    label_action={openUserconsentPopup}
                                                />

                                            </div>
                                            <div className="px-2 pt-4 pb-3 mb-0">
                                                <ButtonPrimary type="submit" text="Отправить" disabled={!formik.isValid || !formik.dirty || 'processing' === formState} />
                                                {(!formik.isValid || !formik.dirty)
                                                    ? <div className="error text-center text-sm text-red-400 mt-1 mr-1">Пожалуйста, заполните поля формы</div>
                                                    : <div className="text-center text-sm mt-1 mr-1">
                                                        <div>
                                                            Этот сайт защищен
                                                            с помощью невидимой reCAPTCHA
                                                            и на нем действуют
                                                        </div>
                                                        <div>
                                                            <a href="https://policies.google.com/privacy" className="mx-1" rel="noopener noreferrer nofollow" target="_blank">Политика конфиденциальности</a>
                                                            <span> и </span>
                                                            <a href="https://policies.google.com/terms" className="mx-1" rel="noopener noreferrer nofollow" target="_blank">Соглашения об использовании</a>
                                                            <span> Google </span>
                                                        </div>
                                                    </div>
                                                }
                                                <ReCAPTCHA
                                                    ref={recaptchaRef}
                                                    size="invisible"
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                                                />
                                            </div>
                                            <Persist name="msg-form" isSessionStorage />
                                        </Form>
                                        {
                                            'processing' === formState && (
                                                <>
                                                    <div className="absolute top-0 left-0 w-full h-full bg-black opacity-30 select-none backdrop-blur rounded-xl"></div>
                                                    <div className="absolute top-0 left-0 w-full h-full">
                                                        <ProcessingIndicator />
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }}
                    </Formik>
                    <Popup opened={popupOpened} title={popupTitle} close={close}>{popupContent}</Popup>
                </>
            )
    }
}

export default FormFeedback
