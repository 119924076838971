import React from 'react'
import { Field, ErrorMessage } from 'formik'
import FormikControlErrorMessage from '../FormikControlErrorMessage'
import newId from '../../../utils/newId'

function FormikControlUserConsent(props) {
    const { name, label_template, label_action, ...rest } = props
    const label = label_template.replace(/#+/g, '')
    const Link = ({ label_template, label_action }) => {
        const rx = /^([^#]*)#([^#]+)#([^#]*)$/
        if (!label_template || !label_template.match(rx)) return null
        const parts = label_template.split(rx)
        return <>
            {parts[1]}<button
                type="button"
                tabIndex={0}
                onClick={label_action}
                className="text-secondary-600
                    underline
                    flex-grow
                    rounded-md
                    outline-none
                    focus:outline-none
                    focus:shadow-outline
                "
            >{parts[2]}</button>{parts[3]}
        </>
    }
    if (!Link({ label_template, label_action })) return false
    const htmlId = newId()
    return (
        <div className="form-control mt-6 mb-4">
            <Field name={name}>
                {({ field }) => {
                    return <>
                        <label htmlFor={htmlId} className="flex items-center p-1 rounded-md cursor-pointer hover:bg-primary-800">
                            <input
                                type="checkbox"
                                id={htmlId}
                                title={label}
                                name={name}
                                {...rest}
                                className="form-checkbox text-primary-800"
                                {...field}
                                checked={field.value}
                                aria-describedby={name + 'Error'}
                            />
                            <span className="ml-8 text-base sm:text-lg "><Link label_template={label_template} label_action={label_action} /></span>
                        </label>
                    </>
                }}
            </Field>
            <ErrorMessage component={FormikControlErrorMessage} name={name} label={label} id={name + 'Error'} />
        </div>
    )
}

export default FormikControlUserConsent
